import { makeStyles } from "@mui/styles";
import get from "../images/get.png";
import header from "../images/getbanner.jpg";
import BgImg1 from "../images/getcardback1.jpg";
import BgImg2 from "../images/getcardback2.jpg";
import BgImg3 from "../images/getcardback3.jpg";
import { ReactComponent as JobDescription } from "../images/job-description.svg";
import { ReactComponent as JobOffer } from "../images/job-offer.svg";
import { ReactComponent as Resume } from "../images/resume.svg";
import { ReactComponent as VerifiedAccount } from "../images/verified-account.svg";
import {
  AccountBalance,
  Description,
  School,
  CheckCircle,
} from "@mui/icons-material";

const useStyles = makeStyles({
  containerfluid: {
    minWidth: "100%",
    position: "relative",
    marginBottom: "7rem",
  },
  container: {
    width: "90%",
    margin: "0rem auto",
    // margin: "50px 60px 10px 50px",
  },
  containerback: {
    backgroundImage: `url(${header})`,
  },
  margin_top: {
    margin: "0rem auto",
    // borderRadius: "10px",
    display: "flex",
    paddingTop: "2rem",
    paddingBottom: "2rem",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      justifyContent: "normal",
    },
  },
  top: {
    minWidth: "800px",
    margin: "0px 0px 0px 400px",
  },
  down_1: {
    width: "650px",
    height: "auto",
    // padding: "1px 0px 1px 50px ",
    // fontFamily: "cursive",
    "@media (max-width: 600px)": {
      width: "auto",
      padding: "1px 0px 1px 10px ",
    },
    "& h1": {
      fontSize: "30px",
    },
  },
  main: {
    textAlign: "center",
    "& h1": {
      fontFamily: "none",
    },
  },
  img_1: {
    height: "400px",
    borderRadius: "20px",
    "@media screen and (max-width:1024px)": {
      display: "none",
    },
  },
  ul: {
    fontFamily: "cursive",
  },
  button: {
    backgroundColor: "#ed8c17",
    height: "40px",
    borderRadius: "5px",
    color: "white",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding: "0px 10px 0px 35px",
    width: "170px",
    fontFamily: "cursive",
    border: "1px white",
  },
  buttons: {
    backgroundColor: "White",
    height: "80px",
    width: "130px",
    borderRadius: "5px",
    color: "black",
    display: "grid",
    placeItems: "center",
    gap: "5px",
    padding: "10px 10px",
    fontWeight: 600,
    textWrap: "nowrap",
    padding: "1rem",
  },
  icon: {
    display: "flex",
    color: "#FD725E",
  },
  btns: {
    display: "flex",
    gap: "30px",
    padding: "1px 0px 1px 0px",
    margin: "100px 0px 0px 1px",
    "@media (max-width: 600px)": {
      margin: "0px",
      padding: "0",
      margin: "0 auto",
      flexWrap: "wrap",
      gap: "12px",
    },
  },
  cards: {
    borderRadius: "10px",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    bottom: "-135px",
    left: "50%",
    boxShadow: "0px 0px 10px -5px",
    width: "90%",
    display: "flex",
    textAlign: "center",
    gap: "5px",
    // margin: "0px 60px 0px 50px",
    "@media (max-width: 600px)": {
      bottom: "0",
      left: "0",
      width: "100%",
      position: "relative",
      flexDirection: "column",
      margin: "0",
      transform: "translate(0, 0)",
    },
  },
  card1: {
    maxWidth: "500px",
    backgroundImage: `url(${BgImg1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "5px",
    color: "black",
    cursor:'pointer',
    padding: "10px",
    fontFamily: "math",
    boxShadow: "0px 0px 10px -5px",
    transition: 'transform 2s',
    "&:hover":{
      transform: "scale(1.2)",
      },
  },
  card2: {
    maxWidth: "500px",
    backgroundImage: `url(${BgImg2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "5px",
    cursor:'pointer',
    color: "black",
    padding: "10px",
    fontFamily: "math",
    boxShadow: "0px 0px 10px -5px",
    transition: 'transform 2s',
    "&:hover":{
    transform: "scale(1.2)",
    },
  },
  card3: {
    maxWidth: "500px",
    backgroundImage: `url(${BgImg3})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    cursor:'pointer',
    borderRadius: "5px",
    color: "black",
    padding: "10px",
    fontFamily: "math",
    boxShadow: "0px 0px 10px -5px",
    transition: 'transform 2s',
    "&:hover":{
      transform: "scale(1.2)",
      },
  },
});

function Jobs() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.containerfluid}>
        <div className={classes.containerback}>
          <div className={classes.container}>
            <div className={classes.margin_top}>
              <div className={classes.down}>
                <div className={classes.down_1}>
                  {/* <p>For Your Latest</p> */}
                  <h1>
                    Get Job
                    <br />
                    That's Right For You
                  </h1>
                </div>
                <div className={classes.btns}>
                  <div className={classes.buttons}>
                    <VerifiedAccount className={classes.icon} />
                    <span>Create Account</span>
                  </div>
                  <div className={classes.buttons}>
                    <Resume className={classes.icon} />
                    <span>Upload Resumes</span>
                  </div>
                  <div className={classes.buttons}>
                    <JobDescription className={classes.icon} />
                    <span>Find Jobs</span>
                  </div>
                  <div className={classes.buttons}>
                    <JobOffer className={classes.icon} />
                    <span>Easy Apply</span>
                  </div>
                </div>
              </div>
              <div>
                <img src={get} className={classes.img_1} />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.cards}>
            <div className={classes.card1}>
              <h3>Discover New Opportunities</h3>
              Explore New Horizons: Uncover Exciting Opportunities to Grow,
              Innovate, and Transform Your Career with Us!
            </div>
            <div className={classes.card2}>
              <h3>Get Alerts For Your Dream Job</h3>
              Stay Ahead of the Game: Get Instant Alerts for Your Dream Job and
              Seize the Opportunity to Advance Your Career!
            </div>
            <div className={classes.card3}>
              <h3>Save Time With 1-Click Apply</h3>
              Streamline Your Job Search: Save Time and Effort with Our 1-Click
              Apply Feature—Your Dream Job is Just a Click Away
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Jobs;
