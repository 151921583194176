import { BsTextWrap } from "react-icons/bs";
import header from "../images/header.png";
import FooterImg from "../images/headerfooter.jpg";
import navbar_logo from "../images/navbar_logo.png";
import { makeStyles } from "@mui/styles";
import CircleNotificationsNoneIcon from "@mui/icons-material/CircleNotifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
} from "@mui/material";
import Dropdown from "./Dropdown";

const useStyles = makeStyles({
  containerfluid: {
    width: "100%",
    backgroundColor: "#062F61",
  },
  container: {
    width: "90%",
    margin: "0 auto",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  header: {
    backgroundColor: "white",
    color: "black",
    overflow: "overlay",
    alignItems: "center",
    "& ul": {
      display: "flex",
      gap: "40px",
      textWrap: "nowrap",
      listStyleType: "none",
      margin: "20px 0px 20px 0px",
      alignItems: "center",
      "@media (max-width: 600px)": {
        gap: "20px",
        padding: "0",
      },
      "& li": {
        cursor: "pointer",
        "& img": {
          width: "100px",
        },
      },
    },
    "& ul li:nth-child(2), & ul li:nth-child(3), & ul li:nth-child(4), & ul li:nth-child(5), & ul li:nth-child(6), & ul li:nth-child(7)":
      {
        "@media screen and (max-width:1070px)": {
          display: "none",
        },
      },
    justifyContent: "space-between",
    display: "flex",
    "& ul li:nth-child(2), & ul li:nth-child(5), & ul li:nth-child(6)": {
      listStyleType: "none",
    },
    // "& ul li:nth-child(3), & ul li:nth-child(4), & ul li:nth-child(7)": {
    //   listStyleType: "disclosure-open",
    // },
    "& > div:nth-child(2)": {
      "& ul": {
        "& > li:nth-child(2)": {
          backgroundColor: "red",
          color: "white",
          width: "110px",
          border: "0px",
          height: "35px",
          margin: "0px 10px 5px 10px",
          backgroundColor: "#e1a405",
          textAlign: "center",
          alignContent: "center",
          display: "inherit",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
  },
  header_1: {
    color: "white",
    overflow: "auto",
    "& ul": {
      display: "flex",
      gap: "40px",
      padding:"0",
      textWrap: "nowrap",
      listStyleType: "none",
      "& > li:nth-child(8)": {
        listStyleType: "disclosure-open",
      },
    },
  },
  center: {
    textAlign: "center",
    backgroundImage: `url(${header})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundBlendMode: "multiply",
    // backgroundColor: "#8ec0ff",
    height: "590px",
    "@media (max-width: 600px)": {
      height: "680px",
    },
    "& > div:nth-child(2)": {
      padding: "20px",
      fontFamily: "fangsong",
      "& h2": {
        color: "white",
        fontSize: "xxx-large",
        margin: "0",
        "& span": {
          backgroundColor: "white",
          color: "#062F61",
        },
      },
      "& p": {
        color: "white",
        marginTop: "auto",
        fontSize: "x-large",
      },
    },
    "& span": {
      color: "white",
      margin: "0px 0 0px 10px",
      "@media (max-width: 600px)": {
        margin: "0px ",
      },
    },
    "& > div:nth-child(5)": {
      "& ul": {
        color: "white",
        display: "flex",
        gap: "20px",
        listStyleType: "none",
        justifyContent: "center",
        alignItems: "center",
        "@media (max-width: 600px)": {
          display: "block",
          padding: "0",
        },
      },
    },
    "& ul > li:first-child": {
      backgroundColor: "#062F61",
      padding: "10px",
    },
  },
  footer: {
    backgroundColor: "#062F61",
    backgroundImage: `url(${FooterImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "white",
    padding: "10px",
    "& h3": {
      textAlign: "center",
    },
    "& ul": {
      overflow: "overlay",
      display: "flex",
      gap: "40px",
      textWrap: "nowrap",
      listStyleType: "none",
      fontSize: "x-large",
      justifyContent: "center",
      padding: "0",
    },
  },
  search: {
    display: "ruby-text",
    marginTop: "30px",
    "& input": {
      height: "50px",
      width: "200px",
      textAlign: "center",
      border: "0px",
    },
    "& > div:nth-child(4)": {
      color: "white",
      border: "0px",
      height: "52px",
      backgroundColor: "#ffffff",
      alignContent: "center",
    },
    "& button": {
      color: "white",
      width: "110px",
      border: "0px",
      height: "35px",
      margin: "0px 10px 0px 10px",
      borderRadius: "5px",
      backgroundColor: "#e1a405",
    },
  },
  menubutton: {
    marginRight: "0.6rem !important",
    display: "none !important",
    "@media (max-width: 600px)": {
      display: "block !important",
    },
  },
  dflex: {
    display: "flex",
  },
  closeicon: {
    display: "flex !important",
    justifyContent: "flex-end !important",
  },
});
function SubHeader() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  
  return (
    <>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          <div className={classes.header_1}>
            <ul>
              <li>Manufacturing</li>
              <li>Engineering & Construction</li>
              <li>Automobile/Auto Ancillaries/Auto Components</li>
              <li>Building Material/Cement/Paint</li>
              <li>FMCG/F&B</li>
              <li> 
        <Dropdown /></li>
           
             
              {/* <li>Furnishings / Sanitaryware / Electricals</li>
              <li>Retail</li>
              <li>Architecture / Interior Design</li>
              <li>Export-Import / Trading</li>
              <li>Industrial Design</li>
              <li>Media/Entertainment</li>
              <li>Metal/Iron/Steel</li>
              <li>Personal Care/Beauty/Wellness</li>
              <li>Pharma & Biotech</li>
              <li>Textile/Garments/Fashion</li>
              <li>Heat Ventilation/Air conditioning</li>
              <li>Automation</li>
              <li>Hospitality</li>
              <li>Heavy machinery </li>
              <li>Brewery/beverages</li> */}
            </ul>
          </div>
        </div>
      </div>
    </>    
  );
}
export default SubHeader;
