import { makeStyles } from "@mui/styles";
import man from "../images/man.png";
import {
  AccountBalance,
  Description,
  School,
  CheckCircle,
} from "@mui/icons-material";
import { ReactComponent as CareerCouncelling } from "../images/CareerCouncelling.svg";
import { ReactComponent as Resumebuilding } from "../images/Resumebuilding.svg";
import { ReactComponent as TalentAquisition } from "../images/TalentAquisition.svg";
import { ReactComponent as VettingProcess } from "../images/VettingProcess.svg";

const useStyles = makeStyles({
  containerfluid: {
    width: "100%",
  },
  container: {
    width: "90%",
    margin: "2rem auto",
  },

  top: {
    minWidth: "min-content",
  },
  down: {
    display: "flex",
    justifyContent: "space-between",
  },
  imgcontainer:{
    width: "50%",
    display: 'flex',
    "@media screen and (min-width: 320px) and (max-width: 767px)": {
      display: 'none',
    },
      },
  down_1: {
    width: "50%",
    height: "auto",
    padding: "50px 0",
    // fontFamily: "cursive",
    "& h3": {
      marginBottom:"30px",
    },
    "& ul": {
      "& > li": {
        marginBottom:"25px",
      }},
    "@media (max-width: 600px)": {
      padding: "1rem",
      width: "90%",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

    },
  },
  main: {
    textAlign: "center",
    "& h1": {
      fontFamily: "none",
    },
  },
  img_1: {
    borderRadius: "20px",
    width:'100%',
    "@media screen and (max-width:870px)": {
      display: "none",
    },
  },
  ul: {
    fontFamily: "cursive",
  },
  button: {
    backgroundColor: "#ed8c17",
    height: "40px",
    marginTop: '40px',
    borderRadius: "5px",
    color: "white",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding: "0px 10px 0px 35px",
    width: "170px",
    border: "1px white",
  },
  buttons: {
    backgroundColor: "#ECFFF8",
    width: '23%',
    height: '150px',
    borderRadius: "5px",
    color: "black",
    display: "grid",
    placeItems: "center",
    gap: "5px",
    padding: "15px 10px",
    // margin: "40px",
    fontWeight: 600,
    textWrap: "nowrap",
    boxShadow: "inset #8eefcb 0px 0px 40px -12px", 
    "@media screen and (min-width: 320px) and (max-width: 767px)": {
      marginBottom:'15px',
      width: '100%',
    },
    // "& .css-i4bv87-MuiSvgIcon-root":{
    //   width: '3em',
    //   height: '3em',
    // },
  },
  buttons_1: {
    backgroundColor: "#FFFBF4",
    boxShadow: "inset #fadca8 0px 0px 40px -12px", 
    width: '23%',
    height: '150px',
    borderRadius: "5px",
    color: "black",
    display: "grid",
    placeItems: "center",
    gap: "5px",
    padding: "15px 10px",
    // margin: "40px",
    fontWeight: 600,
    textWrap: "nowrap",
    "@media screen and (min-width: 320px) and (max-width: 767px)": {
      marginBottom:'15px',
      width: '100%',
    },
    // "& .css-i4bv87-MuiSvgIcon-root":{
    //   width: '3em',
    //   height: '3em',
    // },
  },
  buttons_2: {
    backgroundColor: "#F9F8FF",
    boxShadow: "inset #bfb4ff 0px 0px 40px -12px", 
    width: '23%',
    height: '150px',
    borderRadius: "5px",
    color: "black",
    display: "grid",
    placeItems: "center",
    gap: "5px",
    padding: "15px 10px",
    // margin: "40px",
    fontWeight: 600,
    textWrap: "nowrap",
    "@media screen and (min-width: 320px) and (max-width: 767px)": {
      marginBottom:'15px',
      width: '100%',
    },
    // "& .css-i4bv87-MuiSvgIcon-root":{
    //   width: '3em',
    //   height: '3em',
    // },
  },
  buttons_3: {
    backgroundColor: "#FFF8F8",
    boxShadow: "inset #f7c1c1 0px 0px 40px -12px", 
    width: '23%',
    height: '150px',
    borderRadius: "5px",
    color: "black",
    display: "grid",
    placeItems: "center",
    gap: "5px",
    padding: "15px 10px",
    // margin: "40px",
    fontWeight: 600,
    textWrap: "nowrap",
    "@media screen and (min-width: 320px) and (max-width: 767px)": {
      marginBottom:'15px',
      width: '100%',
    },
    // "& .css-i4bv87-MuiSvgIcon-root":{
    //   width: '3em',
    //   height: '3em',
    // },
  },
  icon: {
    display: "flex",
    color: "#FD725E",
  },
  btns: {
    display: "flex",
    justifyContent: "space-between",
    "@media screen and (min-width: 320px) and (max-width: 767px)": {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
});

function Jobs() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          <div className={classes.main_top}>
            <div className={classes.top}>
              <div className={classes.main}>
                <h1>Why Choose Us</h1>
              </div>
            </div>
            <div className={classes.down}>
              <div className={classes.down_1}>
                <h3>
                At Hyratch, we bridge the gap between top IT professionals and leading opportunities, offering an innovative platform tailored to meet industry demands. Here's why we stand out:
                </h3>
                <ul>
                  <li><b>Expertise in IT Talent:</b> We specialize in matching top IT professionals with the best opportunities.</li>
                  <li><b>Industry Insight:</b> Our deep understanding of technology and industry trends ensures the perfect fit.</li>
                  <li><b>Innovative Platform:</b> Experience seamless and efficient navigation through the talent landscape.</li>
                  <li><b>Commitment to Growth:</b> We focus on helping you excel in your career or business goals.</li>
                  <li><b>Trusted Partnership:</b> Beyond a service, we are dedicated partners in your success.</li>
                </ul>
                <button className={classes.button}>KNOW MORE</button>
              </div>
          
           <div className={classes.imgcontainer}>
           <img src={man} className={classes.img_1} />
           </div>
           
            </div>
            <div className={classes.btns}>
              <div className={classes.buttons}>
                <TalentAquisition className={classes.icon} />
                <span>Talent Aquisition</span>
              </div>
              <div className={classes.buttons_1}>
                <Resumebuilding className={classes.icon} />
                <span>Resume Building</span>
              </div>
              <div className={classes.buttons_2}>
                <CareerCouncelling className={classes.icon} />
                <span>Career Counselling</span>
              </div>
              <div className={classes.buttons_3}>
                <VettingProcess className={classes.icon} />
                <span>Vetting Process</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Jobs;
