import React from 'react';
import { Container, Grid, Typography, Paper, Button, Box } from '@mui/material';
import BlankImg from "../images/careerblank.png"

const customStyles = {
  container: {
    padding: '32px',
  },
  paper: {
    padding: '32px',
    textAlign: 'left',
    color: '#333',
    margin: '16px 0',
  },
  button: {
    marginTop: '16px',
  },
  noJobsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '32px',
  },
  noJobsImage: {
    width: '100%',
    maxWidth: '600px',
    height: 'auto',
  },
};

const jobs = [
  // {
  //   title: 'Software Engineer',
  //   description: 'We are looking for a skilled Software Engineer to join our team. Experience with React and Node.js is required.',
  //   link: '/apply/software-engineer',
  // },
  // {
  //   title: 'Product Manager',
  //   description: 'We are looking for a Product Manager with experience in Agile methodologies and a strong understanding of product lifecycle management.',
  //   link: '/apply/product-manager',
  // },
  // {
  //   title: 'UI/UX Designer',
  //   description: 'Join our team as a UI/UX Designer to create intuitive and engaging user interfaces for our applications.',
  //   link: '/apply/ui-ux-designer',
  // },
  // {
  //   title: 'Data Scientist',
  //   description: 'We are seeking a Data Scientist with expertise in machine learning algorithms and data analysis techniques.',
  //   link: '/apply/data-scientist',
  // },
  // {
  //   title: 'DevOps Engineer',
  //   description: 'Looking for a DevOps Engineer to manage and improve our cloud infrastructure and CI/CD pipelines.',
  //   link: '/apply/devops-engineer',
  // },
  // {
  //   title: 'Sales Executive',
  //   description: 'We need a Sales Executive with a strong background in B2B sales and excellent communication skills.',
  //   link: '/apply/sales-executive',
  // },
];

const Careers = () => {
  return (
    <Container style={customStyles.container}>
      <Typography variant="h3" gutterBottom style={{ marginBottom: '32px' }}>
        Careers
      </Typography>
      <Typography variant="h6" gutterBottom style={{ marginBottom: '32px' }}>
        Join our team and help us make a difference. We offer a dynamic work environment, competitive salaries, and opportunities for professional growth.
      </Typography>
      {jobs.length > 0 ? (
        <Grid container spacing={4}>
          {jobs.map((job, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper style={customStyles.paper}>
                <Typography variant="h5" gutterBottom>
                  {job.title}
                </Typography>
                <Typography variant="body1">
                  {job.description}
                </Typography>
                <Button 
                  variant="contained" 
                  color="primary" 
                  style={customStyles.button}
                  href={job.link}
                >
                  Apply Now
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box style={customStyles.noJobsContainer}>
          <img 
            src={BlankImg} // Replace with actual image URL
            alt="No Job Openings" 
            style={customStyles.noJobsImage} 
          />
        </Box>
      )}
    </Container>
  );
};

export default Careers;
