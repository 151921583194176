import React from "react";


function CommingSoon() {
  return (
    <>
    <h1>
        Comming Soon
    </h1>
    </>
  );
}
export default CommingSoon;
