import React from 'react';
import { Container, Grid, Typography, Paper, Box } from '@mui/material';
import aboutus from "../images/aboutus.jpg";
import ourmission from "../images/ourmission.jpg";
import ourvission from "../images/about-vision.png";
import ourvalue from "../images/ourvalue.jpg";

const customStyles = {
  container: {
    padding: '32px',
  },
  papercontainer:{
display:'flex',
flexDirection: 'column',
justifyContent: 'center',
  },
  paper: {
    display:'flex',
    padding: '32px',
    textAlign: 'left',
    color: '#333',
    margin: '16px 0',
    boxShadow:'none', 
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  section: {
    marginBottom: '32px',
  },
};

const sections = [
  {
    title: 'About Us',
    text: `Hyratch Consultancy Pvt. Ltd is a new-generation startup HR Consultancy. 
    We are an energized team of experts who work together to find the best talent in the market 
    to help companies become more productive and efficient. We take time to understand your business 
    and fulfill the talent need that is, as important as the skills and knowledge required.`,
    image: aboutus, // Replace with actual image URL
  },
  {
    title: 'Our Vision',
    text: 'To be recognized as the most respected and professionally managed HR consultant in India.',
    image: ourvission, // Replace with actual image URL
  },
  {
    title: 'Our Mission',
    text: '“To become an architect of growth for our CLIENTS by providing significant services in the field of Human Resources.”',
    image: ourmission, // Replace with actual image URL
  },
  {
    title: 'Our Values',
    text: `We follow a best practice model that efficiently and affordably finds the right people ready to fit into your organization.
    Service: The willingness to go above and beyond the call of duty by providing the best services under any circumstance. 
    Leverage efficient expertise & knowledge applied to our business processes to maintain a competitive advantage.
    Client-Centricity – Understand that the ultimate purpose is our customer satisfaction. 
    Exceed client expectations in terms of industry knowledge, service quality, time & cost.
    Accountability” - Value our clients through open communication & transparency, timely responses and continual service improvement.
    Environment- Appreciate and foster an environment of trust, integrity and challenge that attracts and retains competent standards of service throughout the entire process.
    Agility – We believe in Moving quickly and lightly in a graceful manner, to meet our client’s requirements.`,
    image: ourvalue, // Replace with actual image URL
  },
];

const AboutUs = () => {
  return (
    <Container style={customStyles.container}>
      {sections.map((section, index) => (
        <Grid
          container
          spacing={4}
          style={customStyles.section}
          key={index}
          direction={index % 2 === 0 ? 'row' : 'row-reverse'}
        >
          <Grid style={customStyles.papercontainer} item xs={12} md={6}>
            <Paper style={customStyles.paper}>
              <Typography variant="h4" gutterBottom>
                {section.title}
              </Typography>
              <Typography variant="body1">{section.text}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <img src={section.image} alt={section.title} style={customStyles.image} />
            </Box>
          </Grid>
        </Grid>
      ))}
    </Container>
  );
};

export default AboutUs;
