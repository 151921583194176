import { makeStyles } from "@mui/styles";
import merc from "../images/merc.jpg";
import sand from "../images/sand.jpg";
import cyba from "../images/cyba.png";
import red from "../images/red.jpg";
import goo from "../images/goo.jpg";
import dcb from "../images/dcb.jpg";
import tech from "../images/tech.png";
import medi from "../images/medi.jpg";

const useStyles = makeStyles({
  containerfluid: {
    minWidth: "100%",
  },
  container: {
    width: "90%",
    margin: "0rem auto",
    padding: "0 0 3rem 0",
  },
  image: {
    width: "25%",
  },
});

function Employers() {
  const classes = useStyles();
  const sections = [
    {
      image: merc, // Replace with actual image URL
    },
    {
      image: sand, // Replace with actual image URL
    },
    {
      image: cyba, // Replace with actual image URL
    },
    {
      image: red, // Replace with actual image URL
    },
    {
      image: goo, // Replace with actual image URL
    },
    {
      image: merc, // Replace with actual image URL
    },
    {
      image: dcb, // Replace with actual image URL
    },
    {
      image: tech, // Replace with actual image URL
    },
    {
      image: medi, // Replace with actual image URL
    },
  ];

  return (
    <>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          {sections.map((section, index) => (
            <img
              src={section.image}
              alt="img"
              className={classes.image}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Employers;
